import React, { useState, useEffect } from "react";
import { Typography, Stack, Box, Button, IconButton, Snackbar, Alert, CircularProgress } from "@mui/material";
import { ButtonPrimaryNormal } from "../../components/Buttons";
import { getDashboardWidgets } from "../../services/dashboard.services";
import { useLocation } from "react-router-dom";
import EditCharts from "../../components/Charts/EditCharts";
import { useOutletContext } from "react-router-dom";
import { updateWidgets } from "../../services/widgets.services";
import CloseIcon from "@mui/icons-material/Close";


export default function EditDashboard() {
  const [currentValue, callBackChartDetails, callbackDrawerHeight] = useOutletContext(null);
  const location = useLocation();
  const { dashboardId, dashboardsName } = location.state;
  const [dashboardData, setDashboardData] = useState(null);
  const [checkActive, setActive] = useState(null);
  const [checkUpdates, setCheckUpdate] = useState(0);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [updating, setUpdating] = useState(false)
  const [checkDelete, setDelete] = useState(false)
  const [checkCreateNewStatus, setCreateNewStatus] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await getDashboardWidgets(dashboardId).then((data) => {
          setDashboardData(data);
          localStorage.removeItem('deleteStatus')
          localStorage.removeItem('createNewStatus')
        });
      } catch (e) {
        throw e;
      }
    })();

   

  }, [dashboardId, currentValue, checkUpdates, checkDelete, checkCreateNewStatus]);

  if (!dashboardData) return null;



  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };


setInterval(function(){
    var value = dashboardData.map((row) => JSON.parse(localStorage.getItem(row.id)));
    var filterData = value.filter(Boolean);
    // console.log(filterData)
    setDelete(localStorage.getItem('deleteStatus'))
    setCreateNewStatus(localStorage.getItem('createNewStatus'))
    setCheckUpdate(filterData.length)
    console.log(checkUpdates)
 }, 1000);





 


  const handleUpdate = (e) => {
    e.preventDefault();
    setUpdating(true)
    const value = dashboardData.map((row) => {
      return JSON.parse(localStorage.getItem(row.id));
    });

    

    const filterData = value.filter(Boolean);

    updateWidgets(filterData).then((data) => {
      if (data.ok) {
        setUpdating(false)
        dashboardData.map((row) => 
          localStorage.removeItem(row.id)
        );
        setOpenSuccess(true);
        // setTimeout(()=> window.location.reload(false),2000)
      }
    });
  };


  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div style={{ marginTop: "2%" }}>
      <Stack
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        marginBottom="2.5%"
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography
            variant="h4"
            color="neutral.Gray_Dk"
            style={{ justifyContent: "center" }}
          >
            Shopify /
          </Typography>
          <Typography
            variant="h4"
            color="neutral.Black_Lit"
            style={{ justifyContent: "center" }}
          >
            {dashboardsName}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
        >
          {updating ? <CircularProgress /> : ''}
          {checkUpdates <= 0 ? '': <Typography variant="body2">Unsaved</Typography>}
          
          {checkUpdates <= 0 ?  <ButtonPrimaryNormal
            variant="contained"
            disabled
          >
            Publish
          </ButtonPrimaryNormal> :  <ButtonPrimaryNormal
            variant="contained"
            onClick={(e) => handleUpdate(e)}
          >
            Publish
          </ButtonPrimaryNormal>}
         
        </Stack>
      </Stack>

      <Box
        sx={{
          position: "relative",
          width: "100%",
          minHeight: callbackDrawerHeight === 'auto' ? "68vh" : "42vh",
          backgroundColor: "#E5E5E5",
          borderRadius: "8px",
          // resize: 'vertical',
          overflow: "scroll",
        }}
      >
        {dashboardData.map((row) => (
          <div
            onClick={() => {
              callBackChartDetails(row);
              setActive(row.id);
            }}
          >
            <EditCharts chartType="column" dashboardData={row} checkActive={checkActive}/>
          </div>
        ))}
        {/* <BasicChart chartType='pie'/>
        <BasicChart chartType='area'/>
        <BasicChart chartType='line'/> */}
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        // severity="error"
        open={openSuccess}
        autoHideDuration={2000}
        onClose={handleClose}
        // message="Note archived"
        action={action}
      >
        <Alert severity="success">
          <b>Success! </b>
          Dashboard Updated
        </Alert>
      </Snackbar>
    </div>
  );
}
