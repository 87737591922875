import * as React from "react";
import { useState } from "react";
import {
  Typography,
  Stack,
  Badge,
  Avatar,
  MenuItem,
  Dialog,
  DialogContent,
  Grid,
} from "@mui/material";
import {
  StyledSelectTextBox,
  StyledTextBox,
} from "../../../../components/TextBox";
import { SmallAvatar } from "../../../../components/Badge";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { updateUserData } from "../../../../services/users.services";
import { ContainedButton } from "../../../../components/Buttons";
// import { useNavigate } from "react-router-dom";
// import PhoneInput from 'react-phone-input-2'
import "react-phone-input-2/lib/style.css";
import "./style.css";
import PhoneInput from "react-phone-input-2";

const AvatarURL =
  "https://unicorn-dev-assests.s3.amazonaws.com/user-profile/avatar/";

export default function StepTwo({ data }) {
  // console.log(data);

  const [value, setValues] = useState({
    address: data.user.address,
    country: data.user.country,
    currency: data.user.currency,
    email: data.user.email,
    encryptedId: data.user.encryptedId,
    firstName: data.user.firstName,
    lastName: data.user.lastName,
    timezone: data.user.timezone,
  });

  const [profile, setProfile] = useState(data.user.imageUrlPath);
  const [contactNo, setContactNo] = useState(data.user.contactNo);

  const [errorCheckFirstName, setErrorCheckFirstName] = useState(false);
  const [errorCheckLastName, setErrorCheckLastName] = useState(false);
  const [errorCheckCountry, setErrorCheckCountry] = useState(false);
  const [errorCheckContactNo, setErrorCheckContactNo] = useState(false);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const navigate = useNavigate();

  // console.log(value)

  const handleUpdate = (e) => {
    e.preventDefault();

    let error = false;

    if (value.firstName === null || value.firstName === "") {
      setErrorCheckFirstName(true);
      error = true;
    }

    if (value.lastName === null || value.lastName === "") {
      setErrorCheckLastName(true);
      error = true;
    }

    if (value.country === null || value.country === "") {
      setErrorCheckCountry(true);
      error = true;
    }

    if (contactNo === null || contactNo === "") {
      setErrorCheckContactNo(true);
      error = true;
    }

    const value2 = { ...value, contactNo: contactNo, imageUrlPath: profile };

    // console.log(error)

    if (!error) {
      updateUserData(value2).then((data) => {
        //if(data.ok){navigate('/onboarding')}
        if (data.ok) {
          window.location = "/onboarding";
        }
      });
    }
  };

  return (
    <div>
      <Stack justifyContent="center" alignItems="center">
        <br></br>
        <Stack spacing={1} textAlign="center">
          <Typography variant="h4">
            Welcome {value.firstName}, Tell us little bit about you.
          </Typography>
          <Typography variant="body2">
            This will help your team to recognize you.
          </Typography>
        </Stack>

        <br></br>
        {/* <Badge anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
          <Avatar sx={{ width: "100%", height: "100%", bgcolor: "#BAD4FF" }}>
            <PersonIcon sx={{ fontSize: "4.25em" }} />
          </Avatar>
        </Badge> */}
        <Badge
          onClick={handleClickOpen}
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            //   <SmallAvatar alt="Remy Sharp" src={EditIcon} />
            <SmallAvatar>
              <EditIcon />
            </SmallAvatar>
          }
        >
          <Avatar src={profile} sx={{ width: "6em", height: "6em" }} />
        </Badge>

        <Dialog
          sx={{
            position: "sticky",
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Grid
              container
              spacing={2}
              alignItems="center"
              paddingLeft="6%"
              paddingTop="3%"
            >
              <Grid xs={3}>
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatarMan1.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatarMan1.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatarMan1.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>
              <Grid xs={3}>
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatarMan2.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatarMan2.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatarMan2.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>
              <Grid xs={3}>
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatarMan3.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatarMan3.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatarMan3.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>
              <Grid xs={3}>
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatarMan4.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatarMan4.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatarMan4.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>

              <Grid xs={3} paddingTop="3%">
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatarGirl1.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatarGirl1.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatarGirl1.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>
              <Grid xs={3} paddingTop="3%">
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatarGirl2.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatarGirl2.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatarGirl2.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>
              <Grid xs={3} paddingTop="3%">
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatarGirl3.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatarGirl3.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatarGirl3.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>

              <Grid xs={3} paddingTop="3%">
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatarGirl4.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatarGirl4.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatarGirl4.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>

              <Grid xs={3} paddingTop="3%">
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatar1.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatar1.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatar1.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>

              <Grid xs={3} paddingTop="3%">
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatar2.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatar2.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatar2.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>

              <Grid xs={3} paddingTop="3%">
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatar3.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatar3.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatar3.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>

              <Grid xs={3} paddingTop="3%">
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatar4.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatar4.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatar4.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>
            </Grid>
          </DialogContent>

          {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
        </Dialog>
      </Stack>
      <br></br>
      <Stack spacing={2}>
        <Stack
          direction="row"
          justifyContent="space-around"
          sx={{ "& > :not(style)": { width: "45%" } }}
        >
          <Stack>
            <Typography variant="body2">
              First Name<span style={{ color: "#EA5455" }}>*</span>
            </Typography>
            <StyledTextBox
              error={errorCheckFirstName ? true : false}
              fullWidth
              id="outlined-adornment-weight"
              placeholder="Joe"
              value={value.firstName}
              onChange={(e) =>
                setValues({ ...value, firstName: e.target.value })
              }
            />
            <Typography variant="body3" color="red">
              {errorCheckFirstName ? " First name required!" : ""}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="body2">
              Last Name<span style={{ color: "#EA5455" }}>*</span>
            </Typography>
            <StyledTextBox
              error={errorCheckLastName ? true : false}
              fullWidth
              id="outlined-adornment-weight"
              placeholder="Blake"
              value={value.lastName}
              onChange={(e) =>
                setValues({ ...value, lastName: e.target.value })
              }
            />
            <Typography variant="body3" color="red">
              {errorCheckLastName ? " Last name required!" : ""}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-around"
          sx={{ "& > :not(style)": { width: "45%" } }}
        >
          <Stack>
            <Typography variant="body2">
              Email<span style={{ color: "#EA5455" }}>*</span>
            </Typography>
            <StyledTextBox
              fullWidth
              disabled
              id="outlined-adornment-weight"
              placeholder="example@gmail.com"
              value={value.email}
            />
          </Stack>
          <Stack>
            <Typography variant="body2">Address</Typography>
            <StyledTextBox
              fullWidth
              id="outlined-adornment-weight"
              value={value.address}
              onChange={(e) => setValues({ ...value, address: e.target.value })}
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-around"
          sx={{ "& > :not(style)": { width: "45%" } }}
        >
          <Stack>
            <Typography variant="body2">
              Country<span style={{ color: "#EA5455" }}>*</span>
            </Typography>
            <StyledSelectTextBox
              error={errorCheckCountry ? true : false}
              fullWidth
              select
              id="outlined-adornment-weight"
              value={value.country}
              onChange={(e) => setValues({ ...value, country: e.target.value })}
            >
              <MenuItem value="USA">United States America</MenuItem>
            </StyledSelectTextBox>
            <Typography variant="body3" color="red">
              {errorCheckCountry ? "Please select the country" : ""}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="body2">
              Contact Number<span style={{ color: "#EA5455" }}>*</span>
            </Typography>
            {/* <StyledTextBox
              error={errorCheckContactNo ? true : false}
              value={value.contactNo}
              onChange={(e) =>
                setValues({ ...value, contactNo: e.target.value })
              }
            /> */}
            <PhoneInput
              country={"us"}
              // inputStyle={{
              //   borderColor: "#d2302f",
              //   "&.react-tel-input .selected-flag": {
              //     borderColor: '#d2302f !important'
              // }
              // }}
              autoFormat={true}
              value={contactNo}
              onChange={setContactNo}
            />
            <Typography variant="body3" color="red">
              {errorCheckContactNo ? "Enter a valid phone number" : ""}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-around"
          sx={{ "& > :not(style)": { width: "45%" } }}
        >
          <Stack>
            <Typography variant="body2">Currency</Typography>
            <StyledSelectTextBox
              fullWidth
              select
              id="outlined-adornment-weight"
              value={value.currency}
              onChange={(e) =>
                setValues({ ...value, currency: e.target.value })
              }
            >
              <MenuItem value="$">USD ($)</MenuItem>
            </StyledSelectTextBox>
          </Stack>
          <Stack>
            <Typography variant="body2">Timezone</Typography>
            <StyledSelectTextBox
              fullWidth
              select
              id="outlined-adornment-weight"
              value={value.timezone}
              onChange={(e) =>
                setValues({ ...value, timezone: e.target.value })
              }
            >
              <MenuItem value="UTC-4 New York, United States">
                UTC-4 New York, United States
              </MenuItem>
            </StyledSelectTextBox>
          </Stack>
        </Stack>
      </Stack>
      <br></br>
      <Stack justifyContent="flex-end" alignItems="center">
        <ContainedButton
          variant="contained"
          onClick={(e) => handleUpdate(e)}
          sx={{ width: "50%" }}
        >
          Continue
        </ContainedButton>
      </Stack>
    </div>
  );
}
