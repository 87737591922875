import React, { useState, useEffect } from "react";
import { Typography, Stack, Grid, Alert } from "@mui/material";
import { StyledTextBox, StyledSelectTextBox } from "../../components/TextBox";
import {
  ButtonPrimaryNormal,
  ButtonSecondaryNormal,
} from "../../components/Buttons";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { getUserDetails } from "../../services/users.services";
import { updateOrganizationsData } from "../../services/organizations.services";
export default function CompanySetting() {
  const [companyname, setCompanyName] = useState("");
  const [companyemail, setCompanyEmail] = useState("");
  const [address, setAddress] = useState("");
  const [employeeemail, setEmployeeEmail] = useState("");
  const [country, setCountry] = useState("");
  const [industry, setIndustry] = useState("");
  const [number, setNumber] = useState("");
  const [currency, setCurrency] = useState("");
  const [timezone, setTimeZone] = useState("");
  const [encryptedId, setEncryptedId] = useState("");

  const [name, setFirstName] = useState("");

  const [updated, setUpdated] = useState(false);
  const [discardChanges, setDiscardChanges] = useState(false);

  const [errorCheckCompanyName, setErrorCheckCompanyName] = useState(false);
  const [errorCheckCompanyEmail, setErrorCheckCompanyEmail] = useState(false);
  // const [errorCheckAddress, setErrorCheckAddress] = useState(false);
  const [errorCheckemployeeEmail, setErrorCheckEmployeeEmail] = useState(false);
  const [errorCheckCountry, setErrorCheckCountry] = useState(false);
  const [errorCheckIndustry, setErrorCheckIndustry] = useState(false);

  const [alert, setAlert] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await getUserDetails().then((data) => {
          setFirstName(data.user.firstName);

          setCompanyName(data.organization.name);
          setCompanyEmail(data.organization.email);
          setAddress(data.organization.address);
          setEmployeeEmail(data.user.email);
          setCountry(data.organization.country);
          setIndustry(data.organization.industry);
          setNumber(data.user.contactNo);
          setCurrency(data.user.currency);
          setTimeZone(data.user.timezone);
          setEncryptedId(data.organization.encryptedId);
        });
      } catch (e) {
        throw e;
      }
      setDiscardChanges(false);
    })();
  }, [discardChanges]);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let error = false;

    if (companyname === "") {
      setErrorCheckCompanyName(true);
      setAlert(true);
      error = true;
    }

    if (companyemail === "") {
      setErrorCheckCompanyEmail(true);
      setAlert(true);
      error = true;
    }
    // if (address === "") {
    //   setErrorCheckAddress(true);
    //   setAlert(true);
    //   error = true;
    // }
    if (employeeemail === "") {
      setErrorCheckEmployeeEmail(true);
      setAlert(true);
      error = true;
    }
    if (country === "") {
      setErrorCheckCountry(true);
      setAlert(true);
      error = true;
    }
    if (industry === "") {
      setErrorCheckIndustry(true);
      setAlert(true);
      error = true;
    }

    const value = {
      address: address,
      contactNo: number,
      country: country,
      currency: currency,
      email: companyemail,
      encryptedId: encryptedId,
      industry: industry,
      name: companyname,
      parent: true,
      timezone: timezone,
    };

    if (!error) {
      updateOrganizationsData(value).then((data) => {
        if (data.ok) {
          setUpdated(true);
        }
      });
    }
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        handleClickAlert={handleCloseAlert}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Stack alignItems="center">
        <Typography variant="h4">
          Hello {name}, You can edit your company information here.
        </Typography>
        <Typography variant="body2">
          You can update your company information in here.
        </Typography>
        <br></br>
        {/* <img src={ProfileIcon} alt="Profile Icon" /> */}
      </Stack>

      <Grid container sx={{ pl: 4, pr: 3 }} justifyContent="center">
        <Grid xs={12} md={5}>
          <Stack>
            <Typography variant="body2" sx={{ paddingBottom: "8px" }}>
              Company Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <StyledTextBox
              fullWidth
              // placeholder="Ticket subject here"
              value={companyname}
              onChange={(e) => setCompanyName(e.target.value)}
              error={errorCheckCompanyName ? true : false}
            ></StyledTextBox>
            <br></br>
          </Stack>
        </Grid>
        <Grid md={1}></Grid>
        <Grid xs={12} md={5}>
          <Stack>
            <Typography variant="body2" sx={{ paddingBottom: "8px" }}>
              Company Email<span style={{ color: "red" }}>*</span>
            </Typography>
            <StyledTextBox
              disabled
              value={companyemail}
              onChange={(e) => setCompanyEmail(e.target.value)}
              error={errorCheckCompanyEmail ? true : false}
            ></StyledTextBox>
            <br></br>
          </Stack>
        </Grid>
      </Grid>

      <Grid container sx={{ pl: 4, pr: 3 }} justifyContent="center">
        <Grid xs={12} md={5}>
          {/* <Stack>
            <Typography variant="body2">
              Designation <span style={{ color: "red" }}>*</span>
            </Typography>
            <StyledTextBox
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              error={errorCheckAddress ? true : false}
            ></StyledTextBox>
            <br></br>
          </Stack> */}

          <Stack>
            <Typography variant="body2" sx={{ paddingBottom: "8px" }}>
              Employee Email <span style={{ color: "red" }}>*</span>
            </Typography>
            <StyledTextBox
              disabled
              value={employeeemail}
              onChange={(e) => setEmployeeEmail(e.target.value)}
              error={errorCheckemployeeEmail ? true : false}
            ></StyledTextBox>
            <br></br>
          </Stack>
        </Grid>
        <Grid md={1}></Grid>
        <Grid xs={12} md={5}>
          {/* <Stack>
            <Typography variant="body2">
              Employee Email <span style={{ color: "red" }}>*</span>
            </Typography>
            <StyledTextBox
              disabled
              value={employeeemail}
              onChange={(e) => setEmployeeEmail(e.target.value)}
              error={errorCheckemployeeEmail ? true : false}
            ></StyledTextBox>
            <br></br>
          </Stack> */}
          <Stack>
            <Typography variant="body2" sx={{ paddingBottom: "8px" }}>
              Country <span style={{ color: "red" }}>*</span>
            </Typography>
            <StyledSelectTextBox
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              error={errorCheckCountry ? true : false}
            >
              <MenuItem value="USA">USA</MenuItem>
              <MenuItem value="Canada">Canada</MenuItem>
              <MenuItem value="SL">Sri Lanka</MenuItem>
            </StyledSelectTextBox>
            <br></br>
          </Stack>
        </Grid>
      </Grid>

      <Grid container sx={{ pl: 4, pr: 3 }} justifyContent="center">
        <Grid xs={12} md={5}>
          {/* <Stack>
            <Typography variant="body2">
              Country <span style={{ color: "red" }}>*</span>
            </Typography>
            <StyledSelectTextBox
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              error={errorCheckCountry ? true : false}
            >
              <MenuItem value="USA">USA</MenuItem>
              <MenuItem value="Canada">Canada</MenuItem>
              <MenuItem value="SL">Sri Lanka</MenuItem>
            </StyledSelectTextBox>
            <br></br>
          </Stack> */}
          <Stack>
            <Typography variant="body2" sx={{ paddingBottom: "8px" }}>
              Industry <span style={{ color: "red" }}>*</span>
            </Typography>
            <StyledSelectTextBox
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
              error={errorCheckIndustry ? true : false}
            >
              <MenuItem value={industry}>{industry}</MenuItem>
            </StyledSelectTextBox>
          </Stack>
        </Grid>
        <Grid md={1}></Grid>
        <Grid xs={12} md={5}>
          {/* <Stack>
            <Typography variant="body2">
              Industry <span style={{ color: "red" }}>*</span>
            </Typography>
            <StyledSelectTextBox
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
              error={errorCheckIndustry ? true : false}
            >
              <MenuItem value={industry}>{industry}</MenuItem>
            </StyledSelectTextBox>
          </Stack> */}
        </Grid>
      </Grid>

      <br></br>

      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        sx={{ marginRight: "6%" }}
      >
        <ButtonSecondaryNormal
          size="large"
          onClick={() => setDiscardChanges(true)}
        >
          Discard Changes
        </ButtonSecondaryNormal>
        <ButtonPrimaryNormal size="large" onClick={(e) => handleSubmit(e)}>
          Save Changes
        </ButtonPrimaryNormal>
      </Stack>

      <Snackbar
        open={alert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        // message="Please fill the required fields"
        action={action}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="error"
          sx={{ backgroundColor: "#FEEFEE" }}
        >
          Please fill the required fields
        </Alert>
      </Snackbar>

      <Snackbar
        open={updated}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        // message="Please fill the required fields"
        action={action}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleCloseAlert} severity="success">
          Profile updated successfully!
        </Alert>
      </Snackbar>

      <br></br>
    </div>
  );
}
