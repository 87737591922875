import * as React from "react";
import {
  CardContent,
  Typography,
  Grid,
  Stack
} from "@mui/material";
import "./style.css";
import MainLogo from "../../assets/fulllogo.svg";
import { ContainedButton } from "../../components/Buttons";
import { StyledCard } from "../../components/Cards";
import { StyledTextBox } from "../../components/TextBox";
// import KeyIcon from '@mui/icons-material/Key';
import { NavLink } from "react-router-dom";


// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import LanguageIcon from '@mui/icons-material/Language';

export default function ForgotPassword() {
  return (
    <div className="main">
        <Grid container spacing={2}>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        alignItems="center"
       
        
      >
        <StyledCard className="cardMain">
          <CardContent>
              <Stack spacing={2}>
            <img src={MainLogo} alt="Logo" style={{width: '8.36375em'}}/>
            <br></br>
            <Typography variant="h4" color="#1D1E30"><b>Forget Password</b></Typography>
            <Stack spacing={1}>
            <Typography variant="body2">We’ll email you a magic code for a password-free sign in. Please enter your email </Typography>
            <StyledTextBox
              fullWidth
              placeholder="Email"
              disabled
              id="outlined-adornment-weight"
            />
            </Stack>
            <br></br>
            <br></br>
            <ContainedButton variant="contained">Request Magic Link</ContainedButton>
            <NavLink to="/signin" className="buttonLinks"><Typography variant="body2" color="#2294DD" textAlign="center">
            <b>Go Back</b>
            </Typography></NavLink> 
            </Stack>
          </CardContent>
        </StyledCard>
        {/* <br></br>
        <StyledCard className="cardBottom">
          <CardContent>
              <Stack direction="row" spacing={2} alignItems="center">
                <KeyIcon fontSize="large" style={{transform: 'rotate(320deg)'}}/>
            <Typography variant="h4"><b>Sign in with SSO</b></Typography>
            </Stack>
          </CardContent>
        </StyledCard> */}
        </Grid>
        <Grid container justifyContent="flex-end" alignItems="flex-end" style={{marginRight: '1%', marginBottom: '2%'}}>
          <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="body3" color="#004B8B">
            Terms of Service
          </Typography>
          <Typography variant="body3" color="#004B8B">
          Privacy
          </Typography>
          {/* <Stack direction="row" spacing={1} alignItems="center">
            <LanguageIcon sx={{ color:"#004B8B"}}/>
          <Typography variant="body3" color="#004B8B">
          Change region
          </Typography>
            <KeyboardArrowDownIcon sx={{ color:"#004B8B"}}/>
          </Stack> */}
         
          </Stack>
          
        </Grid>
      </Grid>
    </div>
  );
}
