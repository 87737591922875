import * as React from "react";
import { useState } from "react";
import {
  CardContent,
  Typography,
  Grid,
  Stack,
  Divider,
  InputAdornment,
  IconButton,
  Button,
  Snackbar,
} from "@mui/material";
import "./style.css";
import MainLogo from "../../assets/fulllogo.svg";
import {
  ButtonPrimaryLarge,
  ButtonPrimaryNormal,
  TextButton,
} from "../../components/Buttons";
import { StyledCard } from "../../components/Cards";
import { StyledTextBox } from "../../components/TextBox";
// import KeyIcon from "@mui/icons-material/Key";
// import { NavLink } from "react-router-dom";
import { postUserRegister } from "../../services/users.services";
import validator from "validator";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import StepOne from "./Onboarding/Steps/StepOne";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import LanguageIcon from "@mui/icons-material/Language";
import { NavLink, useNavigate } from "react-router-dom";
import PasswordStrengthBar from "react-password-strength-bar";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
// import { requestAccessToken } from "../../services/users.services";

export default function CreateAccount() {
  const [fullName, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  

  const [errorCheckFullName, setErrorCheckFullname] = useState(false);
  const [errorCheckEmail, setErrorCheckEmail] = useState(false);
  const [errorCheckPassword, setErrorCheckPassword] = useState(false);
  const [errorCheckPasswordConfirm, setErrorCheckPasswordConfirm] =
    useState(false);

  const [emailMessage, setEmailMessage] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");
  const [passwordConfirmMessage, setPasswordConfirmMessage] = useState("");

  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();
  
  const handleCloseModal = () => {
    setOpenModal(false);
    setTimeout(() => navigate("/onboarding"), 1000);
  };



  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // setOpenSuccess(false);
    setOpen(false);
  };

  const handleUserSubmit = (e) => {
    e.preventDefault();
    const value = { email, fullName, password };
    // const loginValues = { email, password };

    let error = false;

    var letters = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
    );

    if (email === "" || email === null) {
      error = true;
      setErrorCheckEmail(true);
    } else {
      setErrorCheckEmail(false);
    }

    if (validator.isEmail(email)) {
      setEmailMessage("");
      setErrorCheckEmail(false);
    } else {
      setEmailMessage("Enter a valid email");
      setErrorCheckEmail(true);
      error = true;
    }

    if (fullName === "" || fullName === null) {
      error = true;
      setErrorCheckFullname(true);
    } else {
      setErrorCheckFullname(false);
    }

    if (password === "" || password === null) {
      error = true;
      setErrorCheckPassword(true);
    } else {
      setErrorCheckPassword(false);
    }

    if (password.length < 8) {
      error = true;
      setErrorCheckPassword(true);
      setPasswordMessage("Passwords length need to be more than 8 Characters");
    } else {
      setErrorCheckPassword(false);
      setPasswordMessage("");
    }

    if (!letters.test(password)) {
      error = true;
      setErrorCheckPassword(true);
      setPasswordMessage(
        "To become strong password, it must contain number, special character and capital letters"
      );
    } else {
      setErrorCheckPassword(false);
      setPasswordMessage("");
    }

    if (passwordConfirm !== password) {
      error = true;
      setPasswordConfirmMessage("Passwords don't match");
      setErrorCheckPasswordConfirm(true);
    } else {
      setPasswordConfirmMessage("");
      setErrorCheckPasswordConfirm(false);
    }

    // console.log(error)

    if (!error) {
      postUserRegister(value).then((data) => {
        if (data.ok) {
          setPasswordMessage("");
          setErrorCheckPassword(false);

          setEmailMessage("");
          setErrorCheckEmail(false);
          setOpenSuccess(true);
          setErrorCheckFullname(false);
          setOpenModal(true)
          // requestAccessToken(loginValues).then((data) => {
          //   if (data.ok) {
          //     setTimeout(() => navigate("/onboarding"), 2000);
          //   }else{
          //     handleClick();
          //   }
          // });
          
        } else if (data.status === 409) {
          setErrorCheckEmail(true);
          setEmailMessage("Email already exists");
        } else {
          setErrorCheckEmail(true);
          handleClick();
        }
      });
    }
  };

  const handleClick = () => {
    setOpen(true);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="main">
      <Grid container spacing={2}>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="space-between"
          sx={{ padding: "2% 1% 0 2%" }}
        >
          <img src={MainLogo} alt="Logo" style={{ width: "8.36375em" }} />
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography color="#1D1E30">Already have an account?</Typography>
            <NavLink to="/signin" className="buttonLinks">
              {/* <ContainedButton variant="contained" >Sign In</ContainedButton> */}
              <ButtonPrimaryNormal variant="contained">
                Sign In
              </ButtonPrimaryNormal>
            </NavLink>
          </Stack>
        </Grid>
        <Grid direction="column" container alignItems="center">
          <StyledCard className="cardMainCreateAccount">
            <CardContent sx={{ p: 5 }}>
              <Stack spacing={2}>
                <Typography variant="h4" textAlign="center">
                  <b>Hello {fullName === "" ? "There!" : fullName}</b>
                </Typography>
                <Stack spacing={1}>
                  <Typography variant="body2">Full Name</Typography>
                  <StyledTextBox
                    error={errorCheckFullName ? true : false}
                    fullWidth
                    placeholder="Joe Blake"
                    id="outlined-adornment-weight"
                    value={fullName}
                    // error
                    onChange={(e) => setFullname(e.target.value)}
                  />
                  <Typography variant="body3" color="red">
                    {errorCheckFullName ? " Full name required!" : ""}
                  </Typography>
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="body2">Email</Typography>
                  <StyledTextBox
                    error={errorCheckEmail ? true : false}
                    fullWidth
                    placeholder="joeblake@gmail.com"
                    id="outlined-adornment-weight"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Typography variant="body3" color="red">
                    {emailMessage}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ "& > :not(style)": { width: "100%" } }}
                >
                  <Stack spacing={1}>
                    <Typography variant="body2">Password</Typography>
                    <StyledTextBox
                      error={errorCheckPassword ? true : false}
                      fullWidth
                      placeholder="Minimum 8 characters"
                      id="outlined-adornment-weight"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOutlinedIcon />
                            ) : (
                              <VisibilityOffOutlinedIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {password.length <= 0 ? (
                      ""
                    ) : (
                      <PasswordStrengthBar
                        password={password}
                        shortScoreWord=""
                        scoreWords=""
                        minLength="8"
                      />
                    )}
                  </Stack>

                  <Stack spacing={1}>
                    <Typography variant="body2">Confirm Password</Typography>
                    <StyledTextBox
                      error={errorCheckPasswordConfirm ? true : false}
                      fullWidth
                      placeholder="Minimum 8 characters"
                      id="outlined-adornment-weight"
                      value={passwordConfirm}
                      type={showConfirmPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOutlinedIcon/>
                            ) : (
                              <VisibilityOffOutlinedIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      onChange={(e) => setPasswordConfirm(e.target.value)}
                    />
                    <Typography variant="body3" color="red">
                      {passwordConfirmMessage}
                    </Typography>
                  </Stack>
                </Stack>

                <Typography variant="body3" color="red">
                  {passwordMessage}
                </Typography>

                <br></br>
                <br></br>
                {/* <NavLink to="/enter-password" className="buttonLinks"> */}

                <ButtonPrimaryLarge
                  fullWidth
                  variant="contained"
                  onClick={(e) => handleUserSubmit(e)}
                >
                  Create Account
                </ButtonPrimaryLarge>

                {/* </NavLink> */}
              </Stack>
            </CardContent>

            <Divider />
            {/* <hr sx={{width:"420px"}}></hr> */}

            <Stack justifyContent="center" sx={{ pb: 2, pt: 1 }}>
              <Typography variant="body3" textAlign="center">
                By creating account, you agree to our{" "}
                <a
                  href="#index"
                  style={{ color: "#2294DD", textDecoration: "none" }}
                >
                  {" "}
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="#index"
                  style={{ color: "#2294DD", textDecoration: "none" }}
                >
                  {" "}
                  Privacy Policy{" "}
                </a>
              </Typography>
            </Stack>
          </StyledCard>
          {/* <br></br>
          <StyledCard className="cardBottom">
            <CardContent>
              <Stack direction="row" spacing={2} alignItems="center">
                <KeyIcon
                  fontSize="large"
                  style={{ transform: "rotate(320deg)" }}
                />
                <Typography variant="h6">
                  <b>Sign in with SSO</b>
                </Typography>
              </Stack>
            </CardContent>
          </StyledCard> */}
        </Grid>
        {/* <Grid
          container
          justifyContent="flex-end"
          alignItems="flex-end"
          style={{ marginRight: "1%", marginBottom: "2%" }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <LanguageIcon sx={{ color: "#004B8B" }} />
            <Typography variant="body3" color="#004B8B">
              Change region
            </Typography>
            <KeyboardArrowDownIcon sx={{ color: "#004B8B" }} />
          </Stack>
        </Grid> */}
      </Grid>

      <Dialog
        fullScreen={fullScreen}
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>

          <DialogContentText sx={{ p: 2 }}>
<StepOne />
          </DialogContentText>
        </DialogContent>
        <Stack justifyContent="flex-end" alignItems="center">


          <TextButton variant="text" onClick={handleCloseModal}>
            Cancel
          </TextButton>
        </Stack>
        <br></br>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Disagree
          </Button>
          <Button onClick={(e) => handleSubmit(e)}>Agree</Button>
        </DialogActions> */}
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        // severity="error"
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleClose}
        // message="Note archived"
        action={action}
      >
        <Alert severity="success">
          <b>Successfully created! </b> <br></br>
          <Typography variant="body2" sx={{ pt: 1 }}>
            Mail sent and confirm for login
          </Typography>
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        // severity="error"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        // message="Note archived"
        action={action}
      >
        <Alert severity="error">
          <b>Oops! </b>
          Something went wrong
        </Alert>
      </Snackbar>
    </div>
  );
}
