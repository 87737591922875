import * as React from "react";
import { useState } from "react";
import { Typography, Stack, MenuItem, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  StyledTextBox,
  StyledSelectTextBox,
} from "../../../../components/TextBox";
import { ContainedButton } from "../../../../components/Buttons";
import {
  postOrganizationsData,
  updateOrganizationsData,
} from "../../../../services/organizations.services";
// import { useNavigate } from "react-router-dom";
import validator from "validator";

export default function StepThree({ data }) {
  const [tab, setTab] = useState("1");

  // const [value, setValues] = useState({
  //   address: data.organization.address,
  //   contactNo: data.organization.contactNo,
  //   country: data.organization.country,
  //   currency: data.organization.currency,
  //   email: data.organization.email,
  //   industry: data.organization.industry,
  //   name: data.organization.name,
  //   parent: data.organization.parent,
  //   timezone: data.organization.timezone,
  // });


  const [address] = useState(
    data.organization === null ? "" : data.organization.address
  );
  const [contactNo] = useState("");
  const [country, setCountry] = useState(
    data.organization === null ? "" : data.organization.country
  );
  const [currency] = useState("");
  const [email, setEmail] = useState(
    data.organization === null ? "" : data.organization.email
  );
  const [industry, setIndustry] = useState(
    data.organization === null ? "" : data.organization.industry
  );
  const [name, setName] = useState(
    data.organization === null ? "" : data.organization.name
  );
  const [parent] = useState(true);
  const [timezone] = useState("");
  const [encryptedId] = useState(
    data.organization === null ? "" : data.organization.encryptedId
  );


  const [errorCheckEmail, setErrorCheckEmail] = useState(false);
  const [errorCheckName, setErrorCheckName] = useState(false);
  const [errorCheckCountry, setErrorCheckCountry] = useState(false);
  const [errorCheckIndustry, setErrorCheckIndustry] = useState(false);


  const [emailMessage, setEmailMessage] = useState("");
  const [nameMessage, setNameMessage] = useState("");
  const [countryMessage, setCountryMessage] = useState("");
  const [industryMessage, setIndustryMessage] = useState("");
  // const navigate = useNavigate();

  //console.log(encryptedId)

  const handleSubmit = (e) => {
    e.preventDefault();

    let error = false

    if(name === null || name === ''){
      setErrorCheckName(true)
      setNameMessage("Enter a name for your company")
      error = true
    }else{
      setErrorCheckName(false)
      setNameMessage("")
    }


    if(email === null || email === ''){
      setErrorCheckEmail(true)
      setEmailMessage("Enter a valid email")
      error = true
    }else {
      setEmailMessage("Enter a valid email");
      setErrorCheckEmail(false);
    }

    if (validator.isEmail(email)) {
      setEmailMessage("");
      setErrorCheckEmail(false);
    } else {
      setEmailMessage("Enter a valid email");
      setErrorCheckEmail(true);
    }

    if(country === null || country === ''){
      setErrorCheckCountry(true)
      setCountryMessage("Select a country")
      error = true
    }else{
      setErrorCheckCountry(false)
      setCountryMessage("")
    }

    if(industry === null || industry === ''){
      setErrorCheckIndustry(true)
      setIndustryMessage("Select an industry")
      error = true
    }else{
      setErrorCheckIndustry(false)
      setIndustryMessage("")
    }

    let value;
    if (encryptedId === "") {
      value = {
        address,
        contactNo,
        country,
        currency,
        email,
        industry,
        name,
        parent,
        timezone,
      };
      postOrganizationsData(value).then((data) =>{
        if(data.ok){
          window.location = '/onboarding'
        }else if (data.status === 409) {
          setErrorCheckEmail(true);
          setEmailMessage("Email already exists");
        }
      })
    } else {
      value = {
        address,
        contactNo,
        country,
        currency,
        email,
        industry,
        name,
        parent,
        timezone,
        encryptedId,
      };

  


      if(!error){
        updateOrganizationsData(value).then((data) =>{
          if(data.ok){
            window.location = '/onboarding'
          }else if (data.status === 409) {
            setErrorCheckEmail(true);
            setEmailMessage("Email already exists");
          }
        })
      }
      
    }
  };



  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  return (
    <div>
      <Stack justifyContent="center" alignItems="center">
        <br></br>
        <Stack spacing={1} textAlign="center">
          <Typography variant="h4" >
            Thanks, <span style={{textTransform: 'capitalize'}}> {data.user.firstName} {data.user.lastName}</span>. Let’s set up your first company.
          </Typography>
          <Typography variant="body2">
            Setting up your company will help you to generate reports for them.
            You can always head back to company settings and add more companies
            or remove existing ones.
          </Typography>
        </Stack>
      </Stack>
      <br></br>
      <TabContext value={tab}>
        <TabList
          onChange={handleChange}
          aria-label="lab API tabs example"
          style={{ justifyContent: "center" }}
        >
          <Tab
            label={
              <Typography
                variant="body2"
                textTransform="capitalize"
                color="#313131"
              >
                Add New Company
              </Typography>
            }
            value="1"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          />
        </TabList>
        <TabPanel value="1">
          <Stack spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-around"
              sx={{ "& > :not(style)": { width: "60%" } }}
            >
              <Stack>
                <Typography variant="body2">
                  Company Name<span style={{ color: "#EA5455" }}>*</span>
                </Typography>
                <StyledTextBox
                  error={errorCheckName ? true : false}
                  fullWidth
                  id="outlined-adornment-weight"
                  placeholder="Blake Groups LLC"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                 <Typography variant="body3" color="red">
                    {nameMessage}
                  </Typography>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-around"
              sx={{ "& > :not(style)": { width: "60%" } }}
            >
              <Stack>
                <Typography variant="body2">
                  Company Email<span style={{ color: "#EA5455" }}>*</span>
                </Typography>
                <StyledTextBox
                error={errorCheckEmail ? true : false}
                  fullWidth
                  id="outlined-adornment-weight"
                  placeholder="example@blakegroups.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                 <Typography variant="body3" color="red">
                    {emailMessage}
                  </Typography>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-around"
              sx={{ "& > :not(style)": { width: "60%" } }}
            >
              <Stack>
                <Typography variant="body2">
                  Country<span style={{ color: "#EA5455" }}>*</span>
                </Typography>
                <StyledSelectTextBox
              error={errorCheckCountry ? true : false}
              fullWidth
              select
              id="outlined-adornment-weight"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            ><MenuItem value='USA'>United States America</MenuItem></StyledSelectTextBox>
             <Typography variant="body3" color="red">
                    {countryMessage}
                  </Typography>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-around"
              sx={{ "& > :not(style)": { width: "60%" } }}
            >
              <Stack>
                <Typography variant="body2">Industry <span style={{ color: "#EA5455" }}>*</span></Typography>
                <StyledSelectTextBox
                 error={errorCheckIndustry ? true : false}
                  fullWidth
                  id="outlined-adornment-weight"
                  value={industry}
                  onChange={(e) => setIndustry(e.target.value)}
                >
                  <MenuItem value="Other">Other</MenuItem>
                </StyledSelectTextBox>
                <Typography variant="body3" color="red">
                    {industryMessage}
                  </Typography>
              </Stack>
            </Stack>
          </Stack>
        </TabPanel>
        {/* <TabPanel value="2">
          <Stack spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-around"
              sx={{ "& > :not(style)": { width: "60%" } }}
            >
              <Stack>
                <Typography variant="body2">
                  Employee Verification Code
                  <span style={{ color: "#EA5455" }}>*</span>
                </Typography>
                <StyledTextBox
                  fullWidth
                  id="outlined-adornment-weight"
                  placeholder="Blake Groups LLC"
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-around"
              sx={{ "& > :not(style)": { width: "60%" } }}
            >
              <Stack>
                <Typography variant="body2">
                  Employee Email<span style={{ color: "#EA5455" }}>*</span>
                </Typography>
                <StyledTextBox
                  fullWidth
                  id="outlined-adornment-weight"
                  placeholder="example@blakegroups.com"
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-around"
              sx={{ "& > :not(style)": { width: "60%" } }}
            >
              <Stack>
                <Typography variant="body2">
                  Designation<span style={{ color: "#EA5455" }}>*</span>
                </Typography>
                <StyledTextBox
                  fullWidth
                  id="outlined-adornment-weight"
                  placeholder="Marketing Manager"
                />
              </Stack>
            </Stack>
            <br></br>
          </Stack>
        </TabPanel> */}
      </TabContext>
      <br></br>
      <Stack justifyContent="flex-end" alignItems="center">
        <ContainedButton fullWidth
          variant="contained"
          onClick={(e) => handleSubmit(e)}
          sx={{ width: "58%" }}
        >
          Continue
        </ContainedButton>
      </Stack>
    </div>
  );
}
