import * as React from "react";
import {
  Typography,
  Stack,
  Box,
  Grid,
  Chip,
  Snackbar,
  Alert,
  Button,
  IconButton,
  // Checkbox
} from "@mui/material";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ContainedButton, TextButton } from "../../../../components/Buttons";
import { StyledApplicationCard } from "../../../../components/Cards";
import {
  getPlatforms,
  postPlatformStore,
} from "../../../../services/platforms.services";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  // StyledSelectTextBox,
  StyledTextBox,
} from "../../../../components/TextBox";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import CloseIcon from "@mui/icons-material/Close";
// import SupportLogo from "../../../../assets/support.svg";
import OrderLogo from "../../../../assets/order.svg";
import SalesLogo from "../../../../assets/sales.svg";
import ProductLogo from "../../../../assets/product.svg";

import "../../../../assets/style.css";

export default function StepFour({ data }) {
  const [plaforms, setPlatforms] = useState(null);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [storeName, setStoreName] = useState("");
  // const [dataRange, setDataRange] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const [storeNameMessage, setStoreNameMessage] = useState("");
  const [accessTokenMessage, setAccessTokenMessage] = useState("");
  const [errorCommonMessages, setErrorCommonMessages] = useState("")

  const [errorCheckStoreName, setCheckStoreName] = useState(false);
  const [errorAccessToken, setCheckAccessToken] = useState(false);
  const [errorCommon, setErrorCommon] = useState(false)

  const [isConnecting, setIsConnecting] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setIsConnecting(false);
    setCheckStoreName(false);
      setStoreNameMessage("");
      setCheckAccessToken(false);
      setAccessTokenMessage("");
    setOpen(false);
  };

  useEffect(() => {
    (async () => {
      try {
        await getPlatforms().then((data) => {
          setPlatforms(data);
        });
      } catch (e) {
        throw e;
      }
    })();
  }, []);

  if (!plaforms) return null;

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const description = data.platforms.map((row) => row.description);
    // const name = data.platforms.map((row) => row.name);
    const platformId = data.platforms.map((row) => row.encryptedId);
    setIsConnecting(true);
    setErrorCommon(false)

    const value = {
      authorization: {
        applicationId: storeName,
        applicationSecret: accessToken,
        dateRange: 0,
      },
      description: description[0],
      name: storeName,
      organizationId: data.organization.encryptedId,
      platformId: platformId[0],
    };

    let error = false;

    if (storeName === null || storeName === "") {
      setStoreNameMessage("Please enter store name");
      setCheckStoreName(true);
      setIsConnecting(false);
      error = true;
    } else {
      setCheckStoreName(false);
      setStoreNameMessage("");
    }

    if (accessToken === null || accessToken === "") {
      setAccessTokenMessage("Please enter a valid access token");
      setCheckAccessToken(true);
      setIsConnecting(false);
      error = true;
    } else {
      setCheckAccessToken(false);
      setAccessTokenMessage("");
    }

    if(!error){
      postPlatformStore(value).then((data) => {
        console.log(data)
        if (data.status === 201) {
          setIsConnecting(false);
          handleClose()
          setOpenSuccess(true)
          setErrorCommonMessages("")
          window.location = "/unicorn-hub";
        }else if(data.status === 400){
          setIsConnecting(false);
          setErrorCommon(true)
          setErrorCommonMessages("Invalid platform auth credentials")
        }else if(data.status === 409){
          setIsConnecting(false);
          setErrorCommon(true)
          setErrorCommonMessages("Platform store already exists")
        }else if(data.status === 404){
          setIsConnecting(false);
          setErrorCommon(true)
          setErrorCommonMessages("Platform store not found")
        }else if(data.status === 401){
          setIsConnecting(false);
          setErrorCommon(true)
          setErrorCommonMessages("Invalid access token")
        }else{
          setIsConnecting(false);
          setErrorCommon(true)
          setErrorCommonMessages("Oops!!! somthing went wrong")
        }
      });
    }
  };



  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleCloseSuccess}>
        Close
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSuccess}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Stack justifyContent="center" alignItems="center">
        <br></br>
        <Stack spacing={1} textAlign="center">
          <Typography variant="h4">
            That’s Great, Let’s connect your first application.
          </Typography>
          <Typography variant="body2">
            Integrating applications will help your companies to connect with
            various platforms.
          </Typography>
        </Stack>
        <br></br>
        <Box
          sx={{
            width: "43em",
            height: "20.5em",
            backgroundColor: "#F6F6F6",
            padding: "3%",
          }}
        >
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {plaforms.map((row) => (
              <Grid item xs={2} sm={4} md={4}>
                <StyledApplicationCard>
                  <Stack spacing={1} sx={{ padding: "5%" }}>
                    <Stack direction="row" justifyContent="space-between">
                      <img src={row.imageURLPath} alt={row.name} />

                      {data.platforms.length < 0 ? (
                        <Chip
                          label="Connected"
                          color="success"
                          sx={{ color: "#fff" }}
                        />
                      ) : (
                        <Typography
                          variant="body2"
                          sx={{ cursor: "pointer", color: "#2294DD" }}
                          onClick={handleClickOpen}
                        >
                          Connect
                        </Typography>
                      )}
                    </Stack>

                    <Typography variant="body2">
                      <b>{row.name}</b>
                    </Typography>
                    <Typography variant="body2">{row.description}</Typography>
                  </Stack>
                </StyledApplicationCard>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Stack>

      {/* Dialog Start */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Box
              sx={{
                width: "78.37px",
                height: "78.37px",
                borderRadius: 50,
                boxShadow: "0px 6px 12px rgba(13, 51, 115, 0.22)",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="https://unicorn-dev-assests.s3.amazonaws.com/unicornMiniIcon.svg"
                alt="unicorn logo"
                width="65%"
                style={{ padding: "10% 10% 0 15%" }}
              />
            </Box>

            {/* <img src={ConnectingIcon} alt="shopify logo" /> */}
            {!isConnecting ? (
              <Stack direction="row">
                <FiberManualRecordOutlinedIcon fontSize="small" />
                <FiberManualRecordOutlinedIcon fontSize="small" />
                <FiberManualRecordOutlinedIcon fontSize="small" />
              </Stack>
            ) : (
              <Stack direction="row">
                <FiberManualRecordOutlinedIcon
                  className="one"
                  fontSize="small"
                />
                <FiberManualRecordOutlinedIcon
                  className="two"
                  fontSize="small"
                />
                <FiberManualRecordOutlinedIcon
                  className="three"
                  fontSize="small"
                />
              </Stack>
            )}

            <Box
              sx={{
                width: "78.37px",
                height: "78.37px",
                borderRadius: 50,
                boxShadow: "0px 6px 12px rgba(13, 51, 115, 0.22)",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="https://unicorn-dev-assests.s3.amazonaws.com/shopify.svg"
                alt="shopify logo"
                width="65%"
                style={{ padding: "10% 10% 0 15%" }}
              />
            </Box>
          </Stack>
          <br></br>
          <DialogContentText sx={{ p: 2 }}>
            <Stack spacing={1}>
              <Typography
                variant="h5"
                color="neutral.letter_Black"
                textAlign="center"
              >
                <b>Unicorn BI is requesting access to your Shopify store</b>
              </Typography>
              <Typography variant="body2" textAlign="center">
                By giving access permission, you authorize get below information
                from your Shopify store in accordence with their  <a href="#index"> Privacy Policy.</a>
                other application by visiting your app settings page.
              </Typography>
            </Stack>
            <br></br>
            <Stack direction="row" justifyContent="center" spacing={9}>
              <Stack spacing={2}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <img src={ProductLogo} alt="Product" />
                  <Typography variant="body2">Product details.</Typography>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                  <img src={SalesLogo} alt="Sales" />
                  <Typography variant="body2">Sales details.</Typography>
                </Stack>
              </Stack>
              <br></br>
              <Stack spacing={2}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <img src={OrderLogo} alt="Order" />
                  <Typography variant="body2">Order details.</Typography>
                </Stack>

                {/* <Stack direction="row" spacing={2} alignItems="center">
                  <img src={SupportLogo} alt="Support" />
                  <Typography variant="body2">
                    Support request details.
                  </Typography>
                </Stack> */}
              </Stack>
            </Stack>
            <br></br>
            <Stack alignItems="center">
              {errorCommon ? <Alert severity="error">"{errorCommonMessages}"</Alert> : ''}
            </Stack>
            
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ "& > :not(style)": { width: "100%" }, mt: 2 }}
              spacing={2}
            >
              <Stack spacing={1}>
                <Typography variant="body2">Shopify Store Name</Typography>
                <StyledTextBox
                  error={errorCheckStoreName ? true : false}
                  fullWidth
                  value={storeName}
                  onChange={(e) => setStoreName(e.target.value)}
                />
                <Typography variant="body3" color="red">
                  {storeNameMessage}
                </Typography>
              </Stack>
              {/* <Stack spacing={1}>
                <Typography variant="body2">Data Need For</Typography>
                <StyledSelectTextBox
                  sx={{ width: "100%" }}
                  fullWidth
                  value={dataRange}
                  onChange={(e) => setDataRange(e.target.value)}
                >
                  <MenuItem value={7}>7 Days</MenuItem>
                  <MenuItem value={15}>15 Days</MenuItem>
                  <MenuItem value={30}>1 Month</MenuItem>
                </StyledSelectTextBox>
              </Stack> */}
            </Stack>

            <br></br>
            <Stack spacing={1}>
              <Typography variant="body2">API Access Token</Typography>
              <StyledTextBox
                error={errorAccessToken ? true : false}
                fullWidth
                value={accessToken}
                onChange={(e) => setAccessToken(e.target.value)}
              />
              <Typography variant="body3" color="red">
                {accessTokenMessage}
              </Typography>
              <Stack direction="row">
                <Typography variant="body2">
                  Don’t know how to get API access token?
                </Typography>
                <Typography variant="body2" color="primary.Primary_Blue">
                  <a
                    href="https://vezolve.atlassian.net/l/cp/U8hHJ8dd" target="_blank" rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "#2294DD" }}
                  >
                    &nbsp; Get access token
                  </a>
                </Typography>
              </Stack>

              <Typography variant="body2">
              We will be extracting your  <b>Sales</b> data
              </Typography>
              {/* <Stack direction="row" alignItems="center">
              <Checkbox defaultChecked disabled size="small"/>             
               
               <Typography variant="body2">
               
             </Typography>
              </Stack>
                 */}
            </Stack>
          </DialogContentText>
        </DialogContent>
        <Stack justifyContent="flex-end" alignItems="center">
          {!isConnecting ? (
            <ContainedButton
              variant="contained"
              onClick={(e) => handleSubmit(e)}
              sx={{ width: "50%" }}
            >
              Connect Application
            </ContainedButton>
          ) : (
            <ContainedButton variant="contained" sx={{ width: "50%" }}>
              Please Hold Connecting....
            </ContainedButton>
          )}

          <TextButton variant="text" onClick={handleClose}>
            Cancel
          </TextButton>
        </Stack>
        <br></br>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Disagree
          </Button>
          <Button onClick={(e) => handleSubmit(e)}>Agree</Button>
        </DialogActions> */}
      </Dialog>
      {/* Dialog End */}

      <br></br>

      <Stack justifyContent="flex-end" alignItems="center">
        <NavLink to="/unicorn-hub" style={{ textDecoration: "none" }}>
          <ContainedButton variant="contained">Finish Set Up</ContainedButton>
        </NavLink>
      </Stack>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        severity="error"
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
        message="Note archived"
        action={action}
      >
        <Alert severity="success">
          <b>Connected! </b>
          to Application succccesfully
        </Alert>
      </Snackbar>
    </div>
  );
}
