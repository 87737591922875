import * as React from "react";
import {
  CardContent,
  Typography,
  Grid,
  Stack,
  // FormGroup,
  // FormControlLabel,
  // Checkbox,
  InputAdornment,
  IconButton
} from "@mui/material";
import { useState } from "react";
import "./style.css";
import MainLogo from "../../assets/fulllogo.svg";
import { ButtonPrimaryLarge } from "../../components/Buttons";
import { StyledCard } from "../../components/Cards";
import { StyledTextBox } from "../../components/TextBox";
// import KeyIcon from "@mui/icons-material/Key";
import { NavLink } from "react-router-dom";
import { requestAccessToken } from "../../services/users.services";
import { useLocation } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import LanguageIcon from "@mui/icons-material/Language";
import { useNavigate } from "react-router-dom";

export default function StepTwo() {
  const [showPassword, setShowPassword] = useState(false)
  const location = useLocation();
  const { siginEmail } = location.state;

  const [email] = useState(siginEmail);
  const [password, setPassword] = useState(null);
  const [errorMessage, setErrorMessage] = useState("")
  const [errorCheck, setErrorCheck] = useState(false)
  const navigate = useNavigate();

  const handleSignIn = (e) => {
    e.preventDefault();
    const value = { email, password };

    let error = false
    if(password === null || password === ""){
      setErrorMessage("Please enter password")
      setErrorCheck(true)
      error = true
    }else{
      setErrorCheck(false)
      setErrorMessage("")
    }

    if(!error){
      requestAccessToken(value).then((data) => {
        if (data.ok) {
          navigate("/onboarding");
        }else{
          setErrorCheck(true)
          setErrorMessage("Something went wrong please check your Email or Password")
        }
      });
    }

    
  };

  return (
    <div className="main">
      <Grid container spacing={2}>
        <Grid
          container
          direction="column"
          justifyContent="flex-end"
          alignItems="center"
        >
          <StyledCard className="cardMain">
            <CardContent>
              <Stack spacing={2}>
                <img src={MainLogo} alt="Logo" style={{ width: "8.36375em" }} />
                <br></br>
                <Typography variant="h4" color="#1D1E30">
                  <b>Enter Password</b>
                </Typography>
                <Stack spacing={1}>
                  <Typography variant="body2">{email}</Typography>
                  <StyledTextBox
                    fullWidth
                    error={errorCheck ? true : false}
                    id="outlined-adornment-weight"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={showPassword ? "text":"password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <Typography variant="body3" color="red">
                    {errorMessage}
                  </Typography>
                  {/* <FormGroup>
                    <FormControlLabel
                      control={<Checkbox size="small" />}
                      label="Remember me"
                    />
                  </FormGroup> */}
                </Stack>

                <NavLink to="/forgot-password" className="buttonLinks">
                  <Typography variant="body2" color="primary.main">
                    Forget Password
                  </Typography>
                </NavLink>
                <br></br>
                <ButtonPrimaryLarge
                  fullWidth
                  variant="contained"
                  onClick={(e) => handleSignIn(e)}
                >
                  sign In
                </ButtonPrimaryLarge>
                {/* <ContainedButton variant="contained" onClick={(e) => handleSignIn(e)}>Sign In</ContainedButton> */}
                <NavLink to="/signin" className="buttonLinks">
                <Typography variant="body2" color="#2294DD" textAlign="center">
                  <b>Use Different Account</b>
                </Typography>
                </NavLink>
              </Stack>
            </CardContent>
          </StyledCard>
          {/* <br></br>
          <StyledCard className="cardBottom">
            <CardContent>
              <Stack direction="row" spacing={2} alignItems="center">
                <KeyIcon
                  fontSize="large"
                  style={{ transform: "rotate(320deg)" }}
                />
                <Typography variant="h4">
                  <b>Sign in with SSO</b>
                </Typography>
              </Stack>
            </CardContent>
          </StyledCard> */}
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="flex-end"
          style={{ marginRight: "1%", marginBottom: "2%" }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="body3" color="#004B8B">
              Terms of Service
            </Typography>
            <Typography variant="body3" color="#004B8B">
              Privacy
            </Typography>
            {/* <Stack direction="row" spacing={1} alignItems="center">
              <LanguageIcon sx={{ color: "#004B8B" }} />
              <Typography variant="body3" color="#004B8B">
                Change region
              </Typography>
              <KeyboardArrowDownIcon sx={{ color: "#004B8B" }} />
            </Stack> */}
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
