import React, { useState, useEffect } from "react";
import { Typography, Stack, Grid, Alert } from "@mui/material";
import { StyledTextBox, StyledSelectTextBox } from "../../components/TextBox";
import {
  ButtonPrimaryNormal,
  ButtonSecondaryNormal,
} from "../../components/Buttons";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import EditIcon from "@mui/icons-material/Edit";
import { SmallAvatar } from "../../components/Badge";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { getUserDetails, updateUserData } from "../../services/users.services";

const AvatarURL =
  "https://unicorn-dev-assests.s3.amazonaws.com/user-profile/avatar/";

export default function ProfileSettings() {
  const [profile, setProfile] = useState("");
  const [name, setFirstName] = useState("");
  const [lname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [number, setNumber] = useState("");
  const [currency, setCurrency] = useState("");
  const [timezone, setTimeZone] = useState("");
  const [encryptedId, setEncryptedId] = useState("");

  const [errorCheckName, setErrorCheckFirstName] = useState(false);
  const [errorCheckLname, setErrorCheckLname] = useState(false);
  const [errorCheckEmail, setErrorCheckEmail] = useState(false);
  // const [errorCheckAddress, setErrorCheckAddress] = useState(false);
  const [errorCheckCountry, setErrorCheckCountry] = useState(false);
  const [errorCheckNumber, setErrorCheckNumber] = useState(false);
  const [errorCheckCurrency, setErrorCheckCurrency] = useState(false);
  const [errorCheckTimezone, setErrorCheckTimezone] = useState(false);

  const [alert, setAlert] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [discardChanges, setDiscardChanges] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await getUserDetails().then((data) => {
          setProfile(data.user.imageUrlPath);
          setEncryptedId(data.user.encryptedId);
          setFirstName(data.user.firstName);
          setLastName(data.user.lastName);
          setEmail(data.user.email);
          setAddress(data.user.address);
          setCountry(data.user.country);
          setNumber(data.user.contactNo);
          setCurrency(data.user.currency);
          setTimeZone(data.user.timezone);
        });
      } catch (e) {
        throw e;
      }
      setDiscardChanges(false);
    })();
  }, [discardChanges]);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
    setUpdated(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let error = false;

    if (name === "") {
      setErrorCheckFirstName(true);
      setAlert(false);
      error = true;
    }

    if (lname === "") {
      setErrorCheckLname(true);
      setAlert(true);
      error = true;
    }
    if (email === "") {
      setErrorCheckEmail(true);
      setAlert(true);
      error = true;
    }
    if (address === "") {
      // setErrorCheckAddress(true);
      // setAlert(true);
      // error = true;
    }
    if (country === "") {
      setErrorCheckCountry(true);
      setAlert(true);
      error = true;
    }
    if (number === "") {
      setErrorCheckNumber(true);
      setAlert(true);
      error = true;
    }
    if (currency === "") {
      setErrorCheckCurrency(true);
      setAlert(true);
      error = true;
    }
    if (timezone === "") {
      setErrorCheckTimezone(true);
      setAlert(true);
      error = true;
    }
    const value = {
      address: address,
      contactNo: number,
      country: country,
      currency: currency,
      encryptedId: encryptedId,
      firstName: name,
      imageUrlPath: profile,
      lastName: lname,
      timezone: timezone,
    };
    if (!error) {
      updateUserData(value).then((data) => {
        if (data.ok) {
          console.log(data);
          setUpdated(true);
        }
      });
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseAlert}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Stack alignItems="center">
        <Typography variant="h4">
          Hello {name}, You can edit your personal information here.
        </Typography>
        <Typography variant="body2">
          You can update your personal information in here.
        </Typography>
        <br></br>
        {/* <img src={ProfileIcon} alt="Profile Icon" /> */}

        <Badge
          onClick={handleClickOpen}
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            //   <SmallAvatar alt="Remy Sharp" src={EditIcon} />
            <SmallAvatar>
              <EditIcon />
            </SmallAvatar>
          }
        >
          <Avatar src={profile} sx={{ width: "6em", height: "6em" }} />
        </Badge>

        <Dialog
          sx={{
            position: "sticky",
            marginLeft: "17.5em",
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Grid
              container
              spacing={2}
              alignItems="center"
              paddingLeft="6%"
              paddingTop="3%"
            >
              <Grid xs={3}>
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatarMan1.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatarMan1.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatarMan1.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>
              <Grid xs={3}>
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatarMan2.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatarMan2.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatarMan2.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>
              <Grid xs={3}>
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatarMan3.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatarMan3.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatarMan3.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>
              <Grid xs={3}>
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatarMan4.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatarMan4.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatarMan4.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>

              <Grid xs={3} paddingTop="3%">
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatarGirl1.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatarGirl1.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatarGirl1.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>
              <Grid xs={3} paddingTop="3%">
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatarGirl2.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatarGirl2.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatarGirl2.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>
              <Grid xs={3} paddingTop="3%">
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatarGirl3.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatarGirl3.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatarGirl3.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>

              <Grid xs={3} paddingTop="3%">
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatarGirl4.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatarGirl4.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatarGirl4.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>

              <Grid xs={3} paddingTop="3%">
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatar1.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatar1.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatar1.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>

              <Grid xs={3} paddingTop="3%">
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatar2.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatar2.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatar2.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>

              <Grid xs={3} paddingTop="3%">
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatar3.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatar3.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatar3.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>

              <Grid xs={3} paddingTop="3%">
                <Badge
                  onClick={(e) => setProfile(`${AvatarURL}avatar4.svg`)}
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    profile === `${AvatarURL}avatar4.svg` ? (
                      <SmallAvatar>
                        <CheckCircleOutlineOutlinedIcon />
                      </SmallAvatar>
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={`${AvatarURL}avatar4.svg`}
                    sx={{ width: "6em", height: "6em" }}
                  />
                </Badge>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Stack>

      <Grid container sx={{ pl: 4, pr: 3 }} justifyContent="center">
        <Grid xs={12} md={5}>
          <Stack>
            <Typography variant="body2" sx={{ paddingBottom: "8px" }}>
              First Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <StyledTextBox
              fullWidth
              // placeholder="Ticket subject here"
              value={name}
              onChange={(e) => setFirstName(e.target.value)}
              error={errorCheckName ? true : false}
            ></StyledTextBox>
            <br></br>
          </Stack>
        </Grid>
        <Grid md={1}></Grid>
        <Grid xs={12} md={5}>
          <Stack>
            <Typography variant="body2" sx={{ paddingBottom: "8px" }}>
              Last Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <StyledTextBox
              value={lname}
              onChange={(e) => setLastName(e.target.value)}
              error={errorCheckLname ? true : false}
            ></StyledTextBox>
            <br></br>
          </Stack>
        </Grid>
      </Grid>

      <Grid container sx={{ pl: 4, pr: 3 }} justifyContent="center">
        <Grid xs={12} md={5}>
          <Stack>
            <Typography variant="body2" sx={{ paddingBottom: "8px" }}>
              Email <span style={{ color: "red" }}>*</span>
            </Typography>
            <StyledTextBox
              disabled
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={errorCheckEmail ? true : false}
            ></StyledTextBox>
            <br></br>
          </Stack>
        </Grid>
        <Grid md={1}></Grid>
        <Grid xs={12} md={5}>
          <Stack>
            <Typography variant="body2" sx={{ paddingBottom: "8px" }}>
              Address
              {/* <span style={{ color: "red" }}>*</span> */}
            </Typography>
            <StyledTextBox
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              // error={errorCheckAddress ? true : false}
            ></StyledTextBox>
            <br></br>
          </Stack>
        </Grid>
      </Grid>

      <Grid container sx={{ pl: 4, pr: 3 }} justifyContent="center">
        <Grid xs={12} md={5}>
          <Stack>
            <Typography variant="body2" sx={{ paddingBottom: "8px" }}>
              Country <span style={{ color: "red" }}>*</span>
            </Typography>
            <StyledSelectTextBox
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              error={errorCheckCountry ? true : false}
            >
              <MenuItem value="USA">USA</MenuItem>
              <MenuItem value="Canada">Canada</MenuItem>
              <MenuItem value="SL">Sri Lanka</MenuItem>
            </StyledSelectTextBox>
            <br></br>
          </Stack>
        </Grid>
        <Grid md={1}></Grid>
        <Grid xs={12} md={5}>
          <Stack>
            <Typography variant="body2" sx={{ paddingBottom: "8px" }}>
              Contact Number <span style={{ color: "red" }}>*</span>
            </Typography>
            <StyledTextBox
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              error={errorCheckNumber ? true : false}
            ></StyledTextBox>
            <br></br>
          </Stack>
        </Grid>
      </Grid>

      <Grid container sx={{ pl: 4, pr: 3 }} justifyContent="center">
        <Grid xs={12} md={5}>
          <Stack>
            <Typography variant="body2" sx={{ paddingBottom: "8px" }}>
              Currency <span style={{ color: "red" }}>*</span>
            </Typography>
            <StyledSelectTextBox
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              error={errorCheckCurrency ? true : false}
            >
              <MenuItem value="$">USD ($)</MenuItem>
            </StyledSelectTextBox>
            <br></br>
          </Stack>
        </Grid>
        <Grid md={1}></Grid>
        <Grid xs={12} md={5}>
          <Stack>
            <Typography variant="body2" sx={{ paddingBottom: "8px" }}>
              Timezone <span style={{ color: "red" }}>*</span>
            </Typography>
            <StyledSelectTextBox
              value={timezone}
              onChange={(e) => setTimeZone(e.target.value)}
              error={errorCheckTimezone ? true : false}
            >
              <MenuItem value="UTC-4 New York, United States">
                UTC-4 New York, United States
              </MenuItem>
            </StyledSelectTextBox>
            <br></br>
          </Stack>
        </Grid>
      </Grid>

      <br></br>

      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        sx={{ marginRight: "6%" }}
      >
        <ButtonSecondaryNormal
          size="large"
          onClick={() => setDiscardChanges(true)}
        >
          Discard Changes
        </ButtonSecondaryNormal>
        <ButtonPrimaryNormal onClick={(e) => handleSubmit(e)} size="large">
          Save Changes
        </ButtonPrimaryNormal>
      </Stack>

      <Snackbar
        open={alert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        action={action}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="error"
          sx={{ backgroundColor: "#FEEFEE" }}
        >
          Please fill the required fields
        </Alert>
      </Snackbar>

      <Snackbar
        open={updated}
        autoHideDuration={6000}
        onClose={handleClose}
        action={action}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleCloseAlert} severity="success">
          Profile updated successfully!
        </Alert>
      </Snackbar>

      <br></br>
    </div>
  );
}
